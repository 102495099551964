import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { getLoggedUser, removeLoggedUser, setLoggedUser } from "../../defs/funcs/auth";
import { defUrls } from "../../defs/urls";
import ButtonA from "../widgets/button/ButtonA";
import FormA from "../widgets/form/FormA";
import Response from "../widgets/response/Response";
import WrapperA from "../widgets/wrapper/WrapperA";

interface typeRecord {
    name: string;
    email: string;
    error: string;
}
const getFormArrayA = (email: string) => {
    return [
        {
            actedOn: false,
            type: 'text',
            name: 'name',
            placeholder: 'Full Name',
            value: '',
            datalist: [],
            required: true,
            pattern: {
                regEx: /\b([A-ZÀ-ÿ-,a-zA-Z. ']+[ ]*)+/,
                errorMessage: 'invalid name'
            }
        },
        {
            actedOn: false,
            type: 'text',
            name: 'user_name',
            placeholder: 'Choose a unique username',
            value: '',
            datalist: [],
            required: true,
            pattern: {
                regEx: /\b([A-ZÀ-ÿ-,a-zA-Z. ']+[ ]*)+/,
                errorMessage: 'invalid name'
            }
        },
    ];
}
const getFormArrayB = (email: string) => {
    return [
        {
            actedOn: false,
            type: 'text',
            name: 'user_name',
            placeholder: 'Username',
            value: '',
            datalist: [],
            required: true,
            pattern: {
                regEx: /\b([A-ZÀ-ÿ-,a-zA-Z. ']+[ ]*)+/,
                errorMessage: 'invalid name'
            }
        },
    ];
}
const defRecord = {
    name: '',
    email: '',
    error: ''
};
const defFrameProps = {
    toggle: false
};
const defButtonProps = {
    disabled: true,
    loading: false
};
const defResponseProps = {
    key: '',
    type: '',
    text: ''
};

const Broadcast = ({className = ''}: typeHomeA) => {
    const [states, setStates] = useState<any>({
        worker: {
            authed: false,
            id: '',
            name: '',
            user_name: ''
        },
        nav: '',
        form: {},
        record_data: {
            records: [defRecord],
            invalid_records: [],
            add_count: 1
        },
        frames: {
            a: {...defFrameProps}
        },
        buttons: {
            a: {...defButtonProps},
            b: {...defButtonProps}
        },
        responses: {
            a: {...defResponseProps}
        }
    });

    useEffect(() => {
        const worker = getLoggedUser();
        let authed = false;
        if(worker.id && worker.user_name){
            authed = true;
        }

        setStates({
            ...states,
            worker: {
                ...states.worker,
                ...worker,
                authed
            }
        });
    }, []);

    const canBroadcast = (records: typeRecord[]): boolean => {
        let truthy = true;

        for(let a = 0; a < records.length; a++){
            const record = records[a];
            const email = record.email;
            if(!email){
                truthy = false;
                break;
            }
        }

        return truthy;
    }
    const removeReturnCharFromText = (text: string): string => {
        return text.replace('\r', '');
    }
    const makeRecordsFromTexts = (text: string): typeRecord[] => {
        const records: typeRecord[] = [];
        const rows = text.split(`\n`);
        if(rows.length){
            for(let a = 0; a < rows.length; a++){
                const row = rows[a];
                if(row){
                    const columns = row.split(`\t`);
                    if(columns.length){
                        // const name = columns[0];
                        // const email = columns[1] || '';
                        const email = removeReturnCharFromText(columns[0] || '');
                        const name = removeReturnCharFromText(columns[1] || '');
                        
                        records.push({
                            ...defRecord,
                            name,
                            email
                        });
                    }
                }

            }
        }
        return records;
    }
    const concatRecords = (records: typeRecord[], recordsB: typeRecord[]): typeRecord[] => {
        if(recordsB.length){
            //if last record of old one is empty, remove it;
            const len = records.length;
            if(len > 0){
                const record = records[len - 1];
                if(!record.name && !record.email){
                    records.splice((len - 1), 1);
                }
            }

            //concat arrays;
            records = records.concat(recordsB);
        }

        // console.log(records);
        return records;
    }

    const onEvents = ({type = '', value = {}}: typeOnEventObj) => {
        let newStates = {...states};

        if(type === 'change_nav'){
            const nav = value.a || '';

            if(nav === 'sign_up'){
                const capNode = <>
                    Already have worker account?
                    <button
                        className="block font-semibold underline cursro-pointer"
                        onClick={() => onEvents({type: 'change_nav', value: {a: 'sign_in'}})}
                     >
                        Sign in here.
                    </button>
                </>;
                
                const form = {
                    id: 'sign_up',
                    title: 'Sign Up',
                    formArray: getFormArrayA(''),
                    formCaption: {node: capNode},
                    formSubmitObj: {loading: false, text: 'sign up'},
                    responded: false,
                    responses: []
                };

                newStates = {
                    ...states,
                    nav: value.a || '',
                    form: {
                        ...form,
                        key: `${Date.now()}`
                    }
                }
            }
            else if(nav === 'sign_in'){
                const capNode = <>
                    Haven't signed up for a worker account yet?
                    <button
                        className="block font-semibold underline cursro-pointer"
                        onClick={() => onEvents({type: 'change_nav', value: {a: 'sign_up'}})}
                     >
                        Sign up here.
                    </button>
                </>;

                const form = {
                    id: 'sign_in',
                    title: 'Sign In',
                    formArray: getFormArrayB(''),
                    formCaption: {node: capNode},
                    formSubmitObj: {loading: false, text: 'sign in'},
                    responded: false,
                    responses: []
                };

                newStates = {
                    ...states,
                    nav: value.a || '',
                    form: {
                        ...form,
                        key: `${Date.now()}`
                    }
                }
            }
            else if(nav === 'sign_out'){
                removeLoggedUser();
                newStates = {
                    ...states,
                    nav: '',
                    worker: {
                        // ...states.worker,
                        authed: false,
                        id: '',
                        name: '',
                        user_name: ''
                    }
                };
            }
            else {
                newStates = {
                    ...states,
                    nav
                };
            }
        }
        else if(type === 'change_add_count'){
            const e = value.a;
            let count = parseInt(e.target.value || 1);
            if(count < 1){
                count = 1;
            }
            else if(count > 100){
                count = 100;
            }

            newStates = {
                ...states,
                record_data: {
                    ...states.record_data,
                    add_count: count
                }
            };
        }
        else if(type === 'add_records'){
            const newRecords = [];
            for(let a = 0; a < states.record_data.add_count; a++){
                newRecords.push(defRecord);
            }

            newStates = {
                ...states,
                record_data: {
                    ...states.record_data,
                    records: [
                        ...states.record_data.records,
                        ...newRecords
                    ]
                },
                buttons: {
                    ...states.buttons,
                    a: {
                        ...states.buttons.a,
                        disabled: !(canBroadcast(states.record_data.records.concat(newRecords)))
                    }
                }
            };
        }
        else if(type === 'remove_record'){
            const index = value.a;
            const records = states.record_data.records;
            if(records[index]){
                if(records.length > 1){
                    records.splice(index, 1);
                    newStates = {
                        ...states,
                        record_data: {
                            ...states.record_data,
                            records: [...records]
                        },
                        buttons: {
                            ...states.buttons,
                            a: {
                                ...states.buttons.a,
                                disabled: !(canBroadcast(records))
                            }
                        }
                    };
                }
                else if(records.length === 1){
                    newStates = {
                        ...states,
                        record_data: {
                            ...states.record_data,
                            records: [{...defRecord}]
                        },
                        buttons: {
                            ...states.buttons,
                            a: {
                                ...states.buttons.a,
                                disabled: true
                            }
                        }
                    };
                }
            }
        }
        else if(['change_record_name', 'change_record_email'].includes(type)){
            const index = value.b;
            const records = states.record_data.records;
            if(records[index]){
                const e = value.a;
                const inpValue = e.target.value || '';
                records[index] = {
                    ...records[index],
                    name: (type === 'change_record_name' ? inpValue : records[index].name),
                    email: (type === 'change_record_email' ? inpValue : records[index].email)
                };
            }

            newStates = {
                ...states,
                buttons: {
                    ...states.buttons,
                    a: {
                        ...states.buttons.a,
                        disabled: !(canBroadcast(records))
                    }
                }
            };

        }
        else if(type === 'broadcast'){
            const records = states.record_data.records;
            if(records.length){
                // const form = new FormData();
                // form.append('worker_id', `${states.worker.id}`);

                // for(let a = 0; a < records.length; a++){
                //     const record = records[a];
                //     const recordJson = JSON.stringify(record);
                //     form.append('records[]', recordJson);
                // }
                const form = {
                    worker_id: states.worker.id,
                    records,
                };
                const obj = {
                    id: 'broadcast',
                    type: 'submit',
                    form
                };
                newStates = {
                    ...states,
                    buttons: {
                        ...states.buttons,
                        a: {
                            ...states.buttons.a,
                            loading: true
                        }
                    }
                };
                onFormEvents(obj);
            }
        }
        else if(type === 'toggle_frame'){
            const key = value.a || '';
            const newFrames = {...states.frames};
            const frame = newFrames[key];
            if(frame){
                newFrames[key] = {
                    ...frame,
                    toggle: !frame.toggle
                };
                newStates = {
                    ...states,
                    frames: {...newFrames}
                };
            }
        }
        else if(type === 'copy_from_clipboard'){
            if(navigator.clipboard){
                navigator.clipboard.readText()
                    .then(text => {
                        // console.log(text);
                        let records = [...states.record_data.records];
                        const recordsA = makeRecordsFromTexts(text);
                        records = concatRecords(records, recordsA);

                        // console.log(records, recordsA);

                        setStates({
                            ...states,
                            record_data: {
                                ...states.record_data,
                                records: [...records]
                            },
                            responses: {
                                ...states.responses,
                                a: {
                                    ...states.responses.a,
                                    key: `${Date.now()}`,
                                    type: 'success',
                                    text: 'clipboard content read'
                                }
                            },
                            buttons: {
                                ...states.buttons,
                                a: {
                                    ...states.buttons.a,
                                    disabled: !(canBroadcast(records))
                                }
                            }
                        });
                    })
                    .catch(err => {
                        console.log(err);
                        setStates({
                            ...states,
                            responses: {
                                ...states.responses,
                                a: {
                                    ...states.responses.a,
                                    key: `${Date.now()}`,
                                    type: 'error',
                                    text: 'could not read clipboard content'
                                }
                            }
                        });
                    });
            }
            else {
                newStates = {
                    ...states,
                    responses: {
                        ...states.responses,
                        a: {
                            ...states.responses.a,
                            key: `${Date.now()}`,
                            type: 'error',
                            text: 'clipboard incompatibility'
                        }
                    }
                };
            }
        }
        else if(type === 'copy_from_file'){
            const e = value.a || '';
            if(e){
                const files = e.target.files || [];
                if(files.length){
                    const file = files[0];
                    const name = file.name;
                    const index = name.lastIndexOf('.');
                    if(index > -1){
                        const ext = name.substring(index + 1);

                        if(['csv', 'dat', 'et', 'ett', 'ods', 'sql', 'tsv', 'txt', 'xls', 'xlt', 'xlsx'].includes(ext)){
                            const reader = new FileReader();
                            reader.onload = (evt: any) => { // evt = on_file_select event
                                /* Parse data */
                                const bstr = evt.target.result;
                                const wb = XLSX.read(bstr, {type:'binary'});
                                /* Get first worksheet */
                                const wsname = wb.SheetNames[0];
                                const ws = wb.Sheets[wsname];
                                /* Convert array of arrays */
                                // const data = XLSX.utils.sheet_to_csv(ws);
                                const text = XLSX.utils.sheet_to_txt(ws);

                                let records = [...states.record_data.records];
                                const recordsA = makeRecordsFromTexts(text);
                                records = concatRecords(records, recordsA);
        
                                // console.log(records, recordsA);
        
                                setStates({
                                    ...states,
                                    record_data: {
                                        ...states.record_data,
                                        records: [...records]
                                    },
                                    responses: {
                                        ...states.responses,
                                        a: {
                                            ...states.responses.a,
                                            key: `${Date.now()}`,
                                            type: 'success',
                                            text: 'file content read'
                                        }
                                    },
                                    buttons: {
                                        ...states.buttons,
                                        a: {
                                            ...states.buttons.a,
                                            disabled: !(canBroadcast(records))
                                        }
                                    }
                                });

                            };
                            reader.readAsBinaryString(file);
                        }
                        else {
                            //unsupported;
                            // console.log(ext);
                            newStates = {
                                ...states,
                                responses: {
                                    ...states.responses,
                                    a: {
                                        ...states.responses.a,
                                        key: `${Date.now()}`,
                                        type: 'error',
                                        text: `unsupported type, ${ext} given, expecting excel or text file type`
                                    }
                                }
                            };
                        }
                    }
                }
            }
        }

        setStates({
            ...newStates,
        });
    }
    const onFormEvents = (obj: any) => {
        const type = obj.type;
        const id = obj.id;

        if(type === 'submit'){
            let form = obj.form;

            let httpfile = `${defUrls.app.api.base}/api/worker/sign_up/`;
            if(id === 'sign_in'){
                httpfile = `${defUrls.app.api.base}/api/worker/sign_in/`;
            }
            else if(id === 'broadcast'){
                httpfile = defUrls.app.api.broadcast;
                form = JSON.stringify(form);
                // httpfile = `${defUrls.app.api.base}/api/worker/broadcast/`;
            }
            
            fetch(httpfile, {
                method: 'POST',
                body: form,
                headers: id === 'broadcast' ? {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                } : undefined,
            })
            .then(response => response.text())
            .then(outdata => {
                // console.log(outdata);
                const resp: any = JSON.parse(outdata);
                console.log(resp);
                const successful = resp.successful ?? resp.success;
                let error = resp.error;

                let node = <></>;
                let respText = '';
                let statesWorker = {...states.worker};

                if(!error){
                    if(successful){
                        if(['sign_up', 'sign_in'].includes(id)){
                            const worker = resp.data;
                            let authed = false;
                            if(worker.id && worker.user_name){
                                authed = true;
                                setLoggedUser({
                                    id: worker.id,
                                    name: worker.name,
                                    user_name: worker.user_name
                                });
                            }
                            statesWorker = {
                                ...states.worker,
                                ...worker,
                                authed
                            }
                        }
                        else if(id === 'broadcast'){
                            
                        }
                    }
                    else {
                        error = 'something went wrong';
                    }
                }

                if(error){
                    console.log(error);
                    respText = error;
                    node = <div className="flex fill-[red] text-[red] items-center">
                        <svg width="16" height="16" viewBox="0 0 16 16">
                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"></path><path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"></path>
                        </svg>
                        <span className="block ml-1">{error}</span>
                    </div>;
                }

                if(['sign_up', 'sign_in'].includes(id)){
                    setStates({
                        ...states,
                        form: {
                            ...states.form,
                            key: `${Date.now()}`,
                            responded: true,
                            responses: [{node}]
                        },
                        worker: {...statesWorker}
                    });
                }
                else if(id === 'broadcast'){
                    const newRecords = [];
                    const invalidRecords = resp?.data?.invalid_records || [];

                    // console.log(invalidRecords);
                    // const invalidRecords: {key: number, record: {name: string, email: string}, cause: string}[] = states.record_data.invalid_records;
                    if(invalidRecords.length){
                        for(let a = 0; a < invalidRecords.length; a++){
                            const invalidRecord = invalidRecords[a];
                            newRecords.push(invalidRecord);
                        }
                    }

                    if(!newRecords.length){
                        newRecords.push(defRecord);
                    }

                    setStates({
                        ...states,
                        record_data: {
                            ...states.record_data,
                            records: [...newRecords]
                        },
                        responses: {
                            ...states.responses,
                            a: {
                                ...states.responses.a,
                                key: `${Date.now()}`,
                                type: successful ? 'success' : 'error',
                                text: successful ? 'broadcast successful' : (respText || error)
                            }
                        },
                        buttons: {
                            ...states.buttons,
                            a: {
                                ...states.buttons.a,
                                disabled: !(canBroadcast(newRecords)),
                                loading: false
                            }
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                const error = 'fetch error';
                const node = <div className="flex fill-[red] text-[red] items-center">
                    <svg width="16" height="16" viewBox="0 0 16 16">
                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"></path><path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"></path>
                    </svg>
                    <span className="block ml-1">{error}</span>
                </div>;

                if(['sign_up', 'sign_in'].includes(id)){
                    setStates({
                        ...states,
                        form: {
                            ...states.form,
                            key: `${Date.now()}`,
                            responded: true,
                            responses: [{node}]
                        }
                    });
                }
                else if(id === 'broadcast'){
                    setStates({
                        ...states,
                        responses: {
                            ...states.responses,
                            a: {
                                ...states.responses.a,
                                key: `${Date.now()}`,
                                type: 'error',
                                text: error
                            }
                        },
                        buttons: {
                            ...states.buttons,
                            a: {
                                ...states.buttons.a,
                                disabled: !(canBroadcast(states.record_data.records)),
                                loading: false
                            }
                        }
                    });
                }
            });
        }
        else if(type === 'close'){
            onEvents({type: 'change_nav', value: {a: ''}});
        }
    }

    const worker: typeWorker = states.worker;
    const records: typeRecord[] = states.record_data.records;

    return (
        <div className={`${className}`}>
            <div className="fixed w-screen h-screen bg-colPinkA"></div>
            <WrapperA>
                <div className="relative z-[1] xs:flex justify-between p-3 sticky top-0 bg-colPinkA">
                    <div className="font-familyA xs:flex items-center justify-center xs:justify-start">
                        <img className="w-[30px] object-cover mx-auto" src="/icon.png" width={0} height={0} sizes="100vw" alt=""/>
                        <div className="ml-2 text-center xs:text-start">
                            <span className="block mt-4 xs:mt-0 font-semibold text-xl md:text-3xl text-colOrangeB">Broadcast</span>
                            <Link to="/" className="block mt-4 xs:mt-0 font-semibold md:text-xl">Hyper Pels</Link>
                        </div>
                    </div>
                    <div>
                        {
                            worker.authed ?
                            <ButtonA className="mt-4 xs:mt-0 mx-auto block px-8" onClick={() => onEvents({type: 'change_nav', value: {a: 'sign_out'}})}>sign out</ButtonA>
                            :
                            <ButtonA className="mt-4 xs:mt-0 mx-auto block px-8" onClick={() => onEvents({type: 'change_nav', value: {a: 'sign_in'}})}>sign in</ButtonA>
                        }
                    </div>
                </div>
                <div className="">
                    {
                        (worker.authed) ?
                            <div className="relative px-4 md:px-16 mt-12">
                                <div className="text-center">
                                    <div className="md:text-xl font-bold text-black/[.4] font-familyA">Welcome</div>
                                    <div className="text-2xl md:text-4xl mt-4 font-familyA line-clamp-2">{worker.name || worker.user_name}</div>
                                </div>
                                <div className="mt-12">
                                    {
                                        records.map((record, a) => {
                                            return (
                                                <div key={a} className="mb-8 flex md:items-center text-sm justify-between">
                                                    <span className="w-[40px]">{a + 1}.</span>
                                                    <div className="w-[calc(100%-100px)]">
                                                        <div className="md:flex items-center">
                                                            <label
                                                                className={`block relative mb-4 md:mb-0 md:mx-[10px] w-full md:w-[calc(50%-10px)] border-[1px] border-black/[.5] px-4 md:px-6 py-3 md:py-5 rounded-xl`}
                                                                // onClick={e => onFormChange(obj.type, e, a, {action: 'toggle-list'})}
                                                            >
                                                                <input
                                                                    className="input_clear w-full"
                                                                    type="text"
                                                                    value={record.email}
                                                                    placeholder="Email"
                                                                    // disabled={obj.disabled ? obj.disabled === true ? true : false : false}
                                                                    onChange={e => onEvents({type: 'change_record_email', value: {a: e, b: a}})}
                                                                />
                                                            </label>
                                                            <label
                                                                className={`block relative mb-4 md:mb-0 md:mx-[10px] w-full md:w-[calc(50%-10px)] border-[1px] border-black/[.5] px-4 md:px-6 py-3 md:py-5 rounded-xl`}
                                                                // onClick={e => onFormChange(obj.type, e, a, {action: 'toggle-list'})}
                                                            >
                                                                <input
                                                                    className="input_clear w-full"
                                                                    type="text"
                                                                    value={record.name}
                                                                    placeholder="Full Name"
                                                                    // disabled={obj.disabled ? obj.disabled === true ? true : false : false}
                                                                    onChange={e => onEvents({type: 'change_record_name', value: {a: e, b: a}})}
                                                                />
                                                            </label>
                                                        </div>
                                                        {
                                                            (record.error !== '') &&
                                                            <span className="px-[10px] text-[red]">{record.error}</span>
                                                        }
                                                    </div>
                                                    <button
                                                        className="ml-[20px] box-content w-[16px] h-[16px] p-[12px] rounded-full bg-black/[.3] fill-white"
                                                        onClick={() => onEvents({type: 'remove_record', value: {a}})}
                                                    >
                                                        <svg width="16" height="16" viewBox="0 0 16 16">
                                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="mt-2 py-3 sticky bottom-0 bg-colPinkA sm:flex items-center justify-between sm:pl-[50px] sm:pr-[10px]">
                                        <div className="flex sm:flex-row-reverse items-center">
                                            <label
                                                className={`block relative mx-[10px] w-[calc(50%-10px)] border-[1px] border-colGreyC px-4 md:px-6 py-3 md:py-5 rounded-xl`}
                                                // onClick={e => onFormChange(obj.type, e, a, {action: 'toggle-list'})}
                                            >
                                                <input
                                                    className="input_clear w-full"
                                                    type="number"
                                                    value={`${states.record_data.add_count}`}
                                                    placeholder="step"
                                                    min={1}
                                                    max={100}
                                                    // disabled={obj.disabled ? obj.disabled === true ? true : false : false}
                                                    onChange={(e) => onEvents({type: 'change_add_count', value: {a: e}})}
                                                />
                                            </label>
                                            <ButtonA
                                                className="block md:px-8 md:py-4 w-[50%] sm:w-[fit-content]"
                                                onClick={() => onEvents({type: 'add_records'})}
                                            >
                                                add
                                            </ButtonA>
                                        </div>
                                        <div className="mt-8 sm:mt-0 flex items-center">
                                            <div className="">
                                                <ButtonA
                                                    className="md:hidden box-content w-[fit-content] h-[fit-content] !p-4 bg-black/[.5] fill-white !rounded-full"
                                                    onClick={() => onEvents({type: 'toggle_frame', value: {a: 'a'}})}
                                                >
                                                    <svg className="w-[18px] h-[18px]" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                                                    </svg>
                                                </ButtonA>
                                                <div className={`${states.frames.a.toggle ? 'bottom-0' : 'bottom-[-100%] opacity-0 md:opacity-[1]'} fixed md:static z-[1] md:z-[unset] left-0 md:left-[unset] md:bottom-[unset] w-full md:w-[auto] h-screen md:h-[auto] text-sm transitionA`}>
                                                    <div
                                                        className="md:hidden absolute left-0 top-0 w-full h-full bg-black/[.5] cursor-pointer"
                                                        onClick={() => onEvents({type: 'toggle_frame', value: {a: 'a'}})}
                                                    >
                                                    </div>
                                                    <div className={`${states.frames.a.toggle ? 'bottom-0' : 'bottom-[-100%]'} absolute md:static left-0 md:left-[unset] md:bottom-[unset] w-full p-4 md:p-0 bg-white md:bg-transparent`}>
                                                        <div className="md:flex items-center">
                                                            <ButtonA
                                                                className="mx-auto w-full sm:w-[fit-content] h-[fit-content] flex items-center md:box-content !p-4 bg-black/[.5] fill-white md:!rounded-full"
                                                                onClick={() => onEvents({type: 'copy_from_clipboard'})}
                                                            >
                                                                <svg className="w-[16px] md:w-[18px] h-[16px] md:h-[18px]" viewBox="0 0 16 16">
                                                                    <path fillRule="evenodd" d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2"/>
                                                                </svg>
                                                                <span className="ml-4 md:hidden">paste clipboard content</span>
                                                            </ButtonA>
                                                            <ButtonA key={states.responses.a.key} type="label" htmlFor="file" className="mx-auto w-full sm:w-[fit-content] h-[fit-content] flex items-center md:ml-8 my-8 md:my-0 md:box-content !p-4 bg-black/[.5] fill-white md:!rounded-full">
                                                                <input id="file" className="hidden" type="file" accept="*"
                                                                    onChange={(e) => onEvents({type: 'copy_from_file', value: {a: e}})}
                                                                />
                                                                <svg className="w-[16px] md:w-[18px] h-[16px] md:h-[18px]" viewBox="0 0 16 16">
                                                                    <path d="M6 12v-2h3v2z"/>
                                                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3z"/>
                                                                </svg>
                                                                <span className="ml-4 md:hidden">upload a document</span>
                                                            </ButtonA>
                                                        </div>
                                                        <div className="md:hidden">
                                                            <ButtonA
                                                                className="mx-auto sm:mt-0 block md:px-6 md:py-4 bg-transparent !text-black w-full sm:w-[fit-content]"
                                                                onClick={() => onEvents({type: 'toggle_frame', value: {a: 'a'}})}
                                                            >
                                                                close
                                                            </ButtonA>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ButtonA
                                                className="ml-8 block md:px-6 md:py-4 bg-colOrangeB w-full sm:w-[fit-content]"
                                                disabled={states.buttons.a.disabled}
                                                loading={states.buttons.a.loading}
                                                onClick={() => onEvents({type: 'broadcast'})}
                                            >
                                                broadcast
                                            </ButtonA>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <span className="absolute w-[80%] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-center text-3xl text-black/[.7] font-familyA font-semibold capitalize">
                                    sign in required
                                </span>
                                <div className="mt-8">
                                    {
                                        (['sign_up', 'sign_in'].includes(states.nav)) &&
                                        <FormA
                                            key={states.form.key}
                                            id={states.form.id}
                                            title={states.form.title}
                                            formArray={states.form.formArray}
                                            caption={states.form.formCaption}
                                            submit={states.form.formSubmitObj}
                                            responded={states.form.responded}
                                            responses={states.form.responses}
                                            onEvent={onFormEvents}
                                        />
                                    }
                                </div>
                            </div>
                    }
                </div>
                {
                    (states.responses.a.text !== '') &&
                    <Response
                        key_mock={states.responses.a.key}
                        type={states.responses.a.type}
                        text={states.responses.a.text}
                    />
                }
            </WrapperA>
        </div>
    );
}

export default Broadcast;