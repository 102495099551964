import { useEffect, useState } from "react";
import WrapperA from "../wrapper/WrapperA";

const defCaption = {node: <></>, text: ''};
const defSubmit = {loading: false, node: <></>, text: ''};

const FormA = ({id = '', className = '', title = '', formArray, caption = defCaption, submit = defSubmit, responded = false, responses = [], onEvent = () => {}}: typeFormA) => {

    const [states, setStates] = useState<any>({
        form: [],
        submit: submit
    });
    useEffect(() => {
        // console.log(submit.loading, states.submit.loading)
        // console.log(responded);
        if(responded === true){
            setStates({
                ...states,
                submit: {
                    ...states.submit,
                    loading: false
                },
                responded
            });
        }
    }, [responded]);
    useEffect(() => {
        setStates({
            ...states,
            form: formArray
        });
    }, []);

    const buttonProps = {disabled: true};
    for(let a = 0; a < states.form.length; a++){
        const obj = states.form[a];
        if(obj.pattern && obj.pattern.regEx && !obj.pattern.regEx.test(obj.value)){
            buttonProps.disabled = true;
            break;
        }
        else if(obj.required){
            if(obj.value === ''){
                buttonProps.disabled = true;
                break;
            }
            else {
                buttonProps.disabled = false;
            }
        }
        else {
            buttonProps.disabled = false;
        }
    }

    const onFormChange = (type: string, e: any, index: number, argObj: any = {}) => {
        let action = '';
        let subIndex = 0;

        if(argObj.hasOwnProperty('action')){
            action = argObj['action'];
        }
        if(argObj.hasOwnProperty('subIndex')){
            subIndex = argObj['subIndex'];
        }

        let newFormArray = [...states.form];
        
        if(newFormArray[index]){
            let newObj = {
                ...newFormArray[index]
            };

            if(type === 'select'){
                if(action === 'toggle-list'){
                    newObj = {
                        ...newObj,
                        openList: !newObj.openList
                    };
                }
                else if(action === 'close-list'){
                    newObj = {
                        ...newObj,
                        openList: false
                    };
                }
                else if(action === 'option-click'){
                    const value = argObj['subValue'] || '';
                    newObj = {
                        ...newObj,
                        value
                    };
                }
            }
            else {
                const value = e.target.value;
                newObj = {
                    ...newObj,
                    value
                };
                if(action !== 'toggle-list'){
                    newObj = {
                        ...newObj,
                        actedOn: true
                    };
                }

                if(type === 'file'){
                    const files = e.target.files;
                    newObj['files'] = files;
                }
            }

            newFormArray[index] = newObj;
            setStates({
                ...states,
                form: [...newFormArray]
            });
        }
    }

    const submitForm = () => {
        setStates({
            ...states,
            submit: {
                ...states.submit,
                loading: true
            }
        });

        const form = new FormData();

        for(let a = 0; a < states.form.length; a++){
            const obj = states.form[a];
            const type = obj.type;
            const name = obj.name;
            // console.log(name);

            if(type === 'file'){
                const files = obj.files;
                if(files){
                    for(let b = 0; b < files.length; b++){
                        form.append(`${name}[]`, files[b]);
                    }
                }
            }
            else {
                const value = obj.value;
                form.append(`${name}`, value);
            }
        }

        // let form: any = {};
        // states.form.forEach((obj: any) => {
        //     const name = obj.name;
        //     const value = obj.value;
        //     form[name] = value;
        // })

        // console.log(states.form);

        onEvent({type: 'submit', form: form, id});
    }

    return (
        <WrapperA>
            <div className={`${className} relative w-[90%] md:w-[50%] m-auto bg-white px-8 py-12 rounded-3xl`}>
                <div className="mb-2 md:mb-8 text-lg md:text-3xl leading-tight flex items-center justify-between font-semibold text-center font-familyA tracking-wide">
                    <span>{title}</span>
                    <div className="ml-4 shrink-0 p-3 md:p-4 rounded-full bg-colGreyA cursor-pointer" onClick={() => onEvent({type: 'close'})}>
                        <img className="w-[12px] h-[12px]" src={`/images/vectors/vector_014.png`} width={0} height={0} sizes="100vw" alt=""/>
                    </div>
                </div>
                {
                    states.form.map((obj: any, a: number) => {
                        return (
                            <div key={a} className="relative overflow-hidden">
                                <div
                                    className={`
                                        mb-1 md:absolute left-6 top-1 flex items-center fill-[red] text-[red] text-smaller transitionA
                                        ${(
                                            (obj.actedOn && obj.pattern && obj.pattern.regEx && !obj.pattern.regEx.test(obj.value)) ||
                                            (obj.actedOn && obj.required && obj.type === 'file' && !obj.value)
                                        ) ?
                                            '' : 'opacity-0 left-[-100%]'}
                                    `}
                                >
                                    <svg width="16" height="16" viewBox="0 0 16 16">
                                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                                    </svg>
                                    <span className="ml-1">{obj.pattern?.errorMessage || 'invalid value'}</span>
                                </div>
                                <label
                                    className={`block relative box-content border-[1px] border-colGreyC px-4 md:px-6 py-2 md:py-5 rounded-xl ${obj.openList ? 'rounded-b-none mb-1' : 'mb-1 md:mb-5'} ${obj.type === 'textarea' ? 'h-[96px]' : 'h-[32px]'}`}
                                    onClick={e => onFormChange(obj.type, e, a, {action: 'toggle-list'})}
                                >
                                    {
                                        obj.type === 'text' &&
                                        <input className="input_clear w-full" type="text" defaultValue={obj.value} disabled={obj.disabled ? obj.disabled === true ? true : false : false} onChange={e => onFormChange(obj.type, e, a)} />
                                    }
                                    {
                                        obj.type === 'textarea' &&
                                        <textarea className="input_clear resize-none w-full h-full" defaultValue={obj.value} disabled={obj.disabled ? obj.disabled === true ? true : false : false} onChange={e => onFormChange(obj.type, e, a)}></textarea>
                                    }
                                    {
                                        obj.type === 'select' &&
                                        <div className="flex justify-between items-center relative top-[50%] translate-y-[-50%]">
                                            <span className="line-clamp-1">{obj.value}</span>
                                            <img className={`w-[12px] md:w-[20px] h-[12px] md:h-[20px] transitionA ${obj.openList ? 'rotate-180' : ''}`} src="/images/vectors/vector_016.png" width={0} height={0} sizes="100vw" alt=""/>
                                        </div>
                                    }
                                    {
                                        obj.type === 'file' &&
                                        <div className="flex justify-between items-center relative top-[50%] translate-y-[-50%]">
                                            <input className="hidden input_clear w-full" type="file" defaultValue={obj.value} accept={obj.accept} multiple={obj.multiple} disabled={obj.disabled ? obj.disabled === true ? true : false : false} onChange={e => onFormChange(obj.type, e, a)} />
                                            <span className="line-clamp-1">
                                                {
                                                    (obj.files !== undefined && obj.files.length > 0) &&
                                                    Array.from(obj.files).map((file: any, c: number) => {
                                                        return (
                                                            <span key={c}>{(c !== 0 ? '; ' : '') + file.name}</span>
                                                        )
                                                    })
                                                }
                                                {
                                                    !obj.files &&
                                                    obj.value
                                                }
                                            </span>
                                            <img className={`w-[18px] md:w-[28px] h-auto transitionA ${obj.openList ? 'rotate-180' : ''}`} src="/images/vectors/vector_017.png" width={0} height={0} sizes="100vw" alt=""/>
                                        </div>
                                    }
                                    {
                                        obj.value === '' &&
                                        <span className={`absolute px-4 md:px-6 left-0 text-colGreyD line-clamp-1 ${obj.type !== 'textarea' ? 'top-[50%] translate-y-[-50%]' : 'top-2 md:top-5'}`}>{obj.placeholder}</span>
                                    }
                                </label>
                                {
                                    obj.openList === true &&
                                    <div
                                        className="mb-5 block relative border-[1px] border-colGreyB px-6 py-4 rounded-b-xl rounded-t-none"
                                        onMouseLeave={e => onFormChange(obj.type, e, a, {action: 'close-list'})}
                                    >
                                        {
                                            obj.datalist.map((list: any, b: number) => {
                                                return (
                                                    <div key={`${a}_${b}`} 
                                                        className="my-4 flex cursor-pointer"
                                                        onClick={e => onFormChange(obj.type, e, a, {action: 'option-click', subIndex: b, subValue: list.value})}
                                                    >
                                                        <img className="w-[20px] h-[20px]" src={`/images/vectors/${list.value === obj.value ? 'vector_015.png' : 'vector_014.png'}`} width={0} height={0} sizes="100vw" alt=""/>
                                                        <span className="ml-4 text-colGreyC leading-tight">{list.label ? list.label : list.value}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        )
                    })
                }
                {
                    (caption.node !== <></> || caption.text !== '') &&
                    <span className="mt-6 block text-small md:text-sm">
                        {caption.node || caption.text}
                    </span>
                }
                {
                    (responses.length > 0) &&
                    <div className="mt-6 block text-small md:text-sm">
                        {
                            responses.map((response, a) => {
                                return (
                                    response ?
                                    <div key={a} className="">
                                        {response.node}
                                    </div>
                                    :
                                    ''
                                )
                            })
                        }
                    </div>
                }
                {
                    (states.submit.node !== <></> || states.submit.text !== '') &&
                    <button
                        className={`mt-6 block w-full text-white bg-black font-familyA text-sm text-center px-4 py-3 md:p-4 rounded-xl transitionA ${(buttonProps.disabled || states.submit.loading) ? 'cursor-not-allowed opacity-[0.5]' : ''}`}
                        disabled={buttonProps.disabled || states.submit.loading}
                        onClick={submitForm}>
                        {
                            states.submit.loading === true ?
                                <svg className="animate-spin fill-white mx-auto" width="28" height="28" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                </svg>
                                :
                                states.submit.node || states.submit.text
                        }
                    </button>
                }
            </div>
        </WrapperA>
    );
}

export default FormA;