import { HashRouter, Route, Routes } from "react-router-dom";
import Broadcast from "./components/broadcast/Broadcast";
import Home from "./components/home/Home";
import Payment from "./components/payment/Payment";
import './global.css';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/payment" element={<Payment />}/>
        <Route path="/broadcast" element={<Broadcast />}/>
      </Routes>
    </HashRouter>
  );
}

export default App;
