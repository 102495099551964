
'use client';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from "react";

import Home000 from './Home000';
import Home001 from "./Home001";
import Home002 from "./Home002";
import Home003 from "./Home003";
import Home004 from './Home004';
import Home005 from './Home005';
import Home006 from './Home006';
import Home007 from './Home007';
import Home008 from './Home008';
import Home009 from './Home009';
import Home010 from './Home010';

import { stickyNavBar } from '../..//defs/funcs';

const Home = () => {
    const [clientCurrency, setClientCurrency] = useState<string |  undefined>(undefined);
    const [states, setStates] = useState({
        toggleNav: false,
        objInView: {name: ''}
    });
    useEffect(() => {
        AOS.init();
        stickyNavBar();

        fetch(`https://ipapi.co/currency/`)
        .then(resp => resp.text())
        .then((currency) => {
            setClientCurrency(currency);
        })
        .catch((e) => {
            console.log(e);
        });
    }, []);
  
    return (
        <div className="relative overflow-x-hidden hide_scrollbar">
            <Home000 toggleNav={states.toggleNav} objInView={states.objInView}/>
            <Home001 id="_001" name="" onToggleNav={() => setStates({...states, toggleNav: !states.toggleNav})} onView={(objInView: any) => {setStates({...states, objInView: objInView})}} />
            <Home002 id="_002" name="" onView={(objInView: any) => {setStates({...states, objInView: objInView})}} />
            <Home003 id="_003" name="" onView={(objInView: any) => {setStates({...states, objInView: objInView})}} />
            <Home004 id="_004" name="" onView={(objInView: any) => {setStates({...states, objInView: objInView})}} />
            <Home005 id="_005" name="Benefits" onView={(objInView: any) => {setStates({...states, objInView: objInView})}} />
            <Home006 id="_006" name="Recent works" onView={(objInView: any) => {setStates({...states, objInView: objInView})}} />
            <Home007 id="_007" name="Services" onView={(objInView: any) => {setStates({...states, objInView: objInView})}} />
            <Home008 id="_008" name="Pricing" client_currency={clientCurrency} onView={(objInView: any) => {setStates({...states, objInView: objInView})}} />
            <Home009 id="_009" name="FAQs" onView={(objInView: any) => {setStates({...states, objInView: objInView})}} />
            <Home010 id="_010" name="" onView={(objInView: any) => {setStates({...states, objInView: objInView})}} />
        </div>
    );
}

export default Home;