import { useEffect, useRef, useState } from "react";

import { affils } from "../../defs/affils";

import Marquee from "react-fast-marquee";

const Home002 = ({id = '', name = '', className = '', onView = () => {}}: typeHomeA) => {

    const [states, setStates] = useState<any>({
        affils: []
    });

    const localRef = useRef(null);

    useEffect(() => {
        setStates({
            ...states,
            affils: affils
        });
    }, []);

    const motionDiv = <Marquee pauseOnHover={true}>
                            <div className="flex justify-around items-center shrink-0 w-full">
                                {
                                    states.affils.map((affil: any, a: number) => {
                                        return (
                                            <div key={a} className="mx-3 w-[10%] md:w-full h-auto"
                                                style={{minWidth: `calc(${affil.width} / 4)`, maxWidth: affil.width, maxHeight: affil.height}}
                                            >
                                                <img
                                                    className="w-full h-full object-cover"
                                                    src={`/images/affils/${affil.name}`}
                                                    width={0}
                                                    height={0}
                                                    sizes="100vw"
                                                    alt=""
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Marquee>;
  
    return (
        <div ref={localRef} id={id} className={`${className} relative whitespace-nowrap hide_scrollbar bg-black py-6 md:py-8 flex items-center overflow-hidden`}>
            {motionDiv}
        </div>
    );
}

export default Home002;