import { useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";
import WrapperA from "../widgets/wrapper/WrapperA";


const Home003 = ({id = '', name = '', className = '', onView = () => {}}: typeHomeA) => {

    // const [states, setStates] = useState<any>({
    //     things: []
    // });

    const localRef = useRef(null);
    const isInView = useInView(localRef);

    // useEffect(() => {
    //     setStates({
    //         ...states,
    //         things: [
    //             {text: 'Contact', link: '#'}
    //         ]
    //     });
    // }, []);
    useEffect(() => {
        if(isInView){
            onView({
                name: name
            })
        }
    }, [isInView])
  
    return (
        <div ref={localRef} id={id} className={`${className} relative pt-20 pb-40 px-4 md:px-0`}>
            <div className="absolute left-0 top-0 w-[44%] h-[330px] set_bg" style={{backgroundImage: 'url(/images/noises/noise_003.png)'}}></div>
            <WrapperA className="relative">
                <div className="mb-20 text-center">
                    <div className="mb-6 text-3xl md:text-5xl md:w-[70%] m-auto font-familyA font-bold leading-tight tracking-wide">
                        <span>The ultimate solution for agencies, startups, and entrepreneurs.</span>
                    </div>
                    <div className="md:w-[42%] m-auto text-lg md:text-2xl">
                        <span>Design just got a futuristic upgrade. Hyper Pels is the game-changer your brand needs.</span>
                    </div>
                </div>
                <div className="mb-20 text-lg md:flex justify-around text-center md:text-xl">
                    <div className="md:max-w-[280px] md:w-[30%] mb-8 md:mb-0">
                        <svg className="m-auto mb-6 w-[60px] h-[60px] md:w-[76px] md:h-[76px]" width="76" height="76" viewBox="0 0 76 76" fill="none">
                            <path d="M37.9996 2.71387V24.4282M46.1424 59.7139H59.7139M2.71387 24.4282H73.2853V67.8567C73.2853 69.2965 72.7134 70.6772 71.6953 71.6953C70.6772 72.7134 69.2965 73.2853 67.8567 73.2853H8.14244C6.70269 73.2853 5.32191 72.7134 4.30386 71.6953C3.2858 70.6772 2.71387 69.2965 2.71387 67.8567V24.4282Z" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2.71387 24.4282L10.8567 8.73958C11.7346 6.97134 13.0779 5.47591 14.7423 4.41418C16.4066 3.35244 18.3289 2.76459 20.3024 2.71387H55.6967C57.7166 2.71494 59.6961 3.27946 61.4128 4.34396C63.1294 5.40845 64.515 6.93071 65.4139 8.73958L73.2853 24.4282" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="block">Choose a plan and get unlimited design requests.</span>
                    </div>
                    <div className="md:max-w-[280px] md:w-[30%] mb-8 md:mb-0">
                        <svg className="m-auto mb-6 w-[60px] h-[60px] md:w-[76px] md:h-[76px]" width="76" height="76" viewBox="0 0 67 73" fill="none">
                            <path d="M47.4969 3.96041C55.9278 3.96041 62.7871 10.8197 62.7871 19.2507V47.2215C62.7871 55.652 55.9278 62.5118 47.4969 62.5118H19.5261C11.0951 62.5118 4.2358 55.652 4.2358 47.2215V19.2507C4.2358 10.8197 11.0951 3.96041 19.5261 3.96041H47.4969ZM47.4969 0.60918H19.5261C9.23084 0.60918 0.88457 8.95498 0.88457 19.2507V47.2215C0.88457 57.5167 9.23083 65.863 19.5261 65.863H47.4969C57.7921 65.863 66.1384 57.5167 66.1384 47.2215V19.2507C66.1384 8.95498 57.7921 0.60918 47.4969 0.60918Z" fill="black" stroke="black" strokeWidth="0.6"/>
                            <path d="M25.2957 43.8562L31.6597 37.4922M39.6737 34.6622L37.7687 40.3772C37.7383 40.4688 37.6862 40.5518 37.617 40.619C37.5477 40.6862 37.4632 40.7357 37.3707 40.7632L25.6927 44.2342C25.5875 44.2655 25.4757 44.2673 25.3696 44.2394C25.2634 44.2115 25.1669 44.1551 25.0906 44.0762C25.0143 43.9973 24.9611 43.899 24.9368 43.792C24.9125 43.685 24.918 43.5733 24.9527 43.4692L28.7447 32.0952C28.7719 32.0136 28.8163 31.9387 28.875 31.8757C28.9336 31.8127 29.0052 31.7631 29.0847 31.7302L34.4717 29.5122C34.5812 29.467 34.7017 29.4552 34.8179 29.4783C34.9341 29.5015 35.0409 29.5585 35.1247 29.6422L39.5287 34.0472C39.6077 34.1261 39.6631 34.2255 39.6887 34.3341C39.7143 34.4427 39.7091 34.5564 39.6737 34.6622Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M39.7914 34.3104L42.6194 31.4814C42.9943 31.1063 43.2049 30.5977 43.2049 30.0674C43.2049 29.5371 42.9943 29.0284 42.6194 28.6534L40.4994 26.5314C40.3136 26.3456 40.0931 26.1982 39.8504 26.0976C39.6077 25.9971 39.3476 25.9453 39.0849 25.9453C38.8221 25.9453 38.562 25.9971 38.3193 26.0976C38.0766 26.1982 37.8561 26.3456 37.6704 26.5314L34.8424 29.3604M33.7804 35.3704C33.641 35.2311 33.4756 35.1207 33.2936 35.0453C33.1116 34.97 32.9165 34.9312 32.7195 34.9313C32.5225 34.9313 32.3274 34.9702 32.1455 35.0456C31.9635 35.121 31.7981 35.2316 31.6589 35.3709C31.5196 35.5102 31.4091 35.6756 31.3338 35.8576C31.2584 36.0397 31.2197 36.2347 31.2197 36.4317C31.2198 36.6287 31.2586 36.8238 31.3341 37.0058C31.4095 37.1878 31.52 37.3531 31.6594 37.4924C31.9407 37.7736 32.3223 37.9316 32.7202 37.9315C33.1181 37.9314 33.4996 37.7733 33.7809 37.4919C34.0621 37.2105 34.2201 36.8289 34.22 36.431C34.2199 36.0332 34.0617 35.6516 33.7804 35.3704Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M60.7488 59.2666C60.7488 66.8637 54.5902 70.587 46.9927 70.587H21.4572C13.8602 70.587 7.66211 68.2393 7.66211 60.6422" stroke="black" strokeWidth="3" strokeMiterlimit="10"/>
                            <path d="M19.1003 49.4991H15.7403C15.2123 49.4991 14.7803 49.0671 14.7803 48.5391C14.7803 48.0111 15.2123 47.5791 15.7403 47.5791H19.1003C19.6283 47.5791 20.0603 48.0111 20.0603 48.5391C20.0603 49.0671 19.6283 49.4991 19.1003 49.4991Z" fill="#020202"/>
                            <path d="M18.38 46.8594V50.2194C18.38 50.7474 17.948 51.1794 17.42 51.1794C16.892 51.1794 16.46 50.7474 16.46 50.2194V46.8594C16.46 46.3314 16.892 45.8994 17.42 45.8994C17.948 45.8994 18.38 46.3314 18.38 46.8594Z" fill="#020202"/>
                            <path d="M24.7973 13.238C24.7973 14.4404 23.8222 15.4155 22.6199 15.4155C21.4175 15.4155 20.4424 14.4404 20.4424 13.238C20.4424 12.0357 21.4175 11.0605 22.6199 11.0605C23.8222 11.0605 24.7973 12.0357 24.7973 13.238Z" fill="#020202"/>
                        </svg>
                        <span className="block">Get your design within a few business days on average, Monday to Friday.</span>
                    </div>
                    <div className="md:max-w-[280px] md:w-[30%] mb-8 md:mb-0">
                        <svg className="m-auto mb-6 w-[60px] h-[60px] md:w-[76px] md:h-[76px]" width="76" height="76" viewBox="0 0 81 80" fill="none">
                            <g clipPath="url(#clip0_47_5287)">
                                <path d="M74.7527 58.3997C74.5964 57.8454 74.231 57.3735 73.7336 57.0833C73.2361 56.7931 72.6454 56.7074 72.086 56.8442L66.5527 58.3331C69.5806 53.8697 71.4165 48.7061 71.886 43.3331C72.3399 38.3061 71.6049 33.243 69.7403 28.5526C67.8758 23.8623 64.934 19.6763 61.1527 16.333C60.7156 15.9715 60.1567 15.7908 59.5907 15.828C59.0247 15.8652 58.4943 16.1174 58.1082 16.5331C57.7198 16.9753 57.5227 17.5536 57.5602 18.1411C57.5977 18.7285 57.8667 19.2771 58.3082 19.6664C61.5666 22.5425 64.1015 26.1457 65.7076 30.1841C67.3137 34.2226 67.9458 38.5826 67.5527 42.9108C67.1721 47.4698 65.643 51.858 63.1082 55.6664L62.5527 50.0886C62.5946 49.7603 62.5626 49.4269 62.4592 49.1125C62.3557 48.7982 62.1834 48.5109 61.9547 48.2717C61.7261 48.0325 61.4468 47.8474 61.1375 47.7299C60.8281 47.6124 60.4964 47.5654 60.1666 47.5925C59.8368 47.6195 59.5172 47.7199 59.2311 47.8863C58.9451 48.0527 58.6998 48.2808 58.5132 48.5541C58.3266 48.8274 58.2033 49.1389 58.1525 49.4659C58.1017 49.7929 58.1245 50.1271 58.2194 50.4442L59.3305 64.7553L73.2194 61.0664C73.7695 60.9055 74.2362 60.5382 74.522 60.0412C74.8077 59.5443 74.8904 58.9562 74.7527 58.3997Z" fill="black"/>
                                <path d="M9.90791 37.956C10.2033 38.1459 10.5373 38.2674 10.8857 38.3116C11.4656 38.3877 12.0522 38.232 12.5181 37.8784C12.9841 37.5248 13.2918 37.0017 13.3746 36.4227C14.2928 29.643 17.7383 23.4605 23.021 19.1131C28.3038 14.7658 35.0338 12.5746 41.8635 12.9783L36.8857 16.3783C36.5335 16.6436 36.2682 17.0077 36.1235 17.4243C35.9789 17.8408 35.9615 18.291 36.0735 18.7175C36.1854 19.144 36.4218 19.5276 36.7524 19.8194C37.083 20.1111 37.493 20.2979 37.9301 20.356C38.4308 20.4018 38.9321 20.2765 39.3523 20.0005L51.1968 11.8671L41.0635 1.68936C40.8831 1.41306 40.6436 1.18037 40.3621 1.0082C40.0806 0.836023 39.7644 0.728684 39.4363 0.693973C39.1082 0.659262 38.7764 0.698049 38.4652 0.807518C38.1539 0.916987 37.871 1.09439 37.6368 1.32685C37.4027 1.55931 37.2232 1.84099 37.1115 2.15145C36.9998 2.4619 36.9586 2.79334 36.991 3.12169C37.0233 3.45004 37.1283 3.76707 37.2985 4.04977C37.4686 4.33246 37.6996 4.57372 37.9746 4.75603L41.7523 8.53381C33.8653 8.15669 26.1235 10.7502 20.0552 15.8023C13.9869 20.8544 10.0332 27.9979 8.97457 35.8227C8.9176 36.2298 8.97467 36.6448 9.13944 37.0214C9.30422 37.398 9.57023 37.7216 9.90791 37.956Z" fill="black"/>
                                <path d="M48.6191 66.5114C45.149 67.6199 41.4885 68.0056 37.8635 67.6448C33.4488 67.212 29.2057 65.7113 25.5005 63.2722C21.7954 60.8331 18.7396 57.5289 16.5969 53.6448L22.1302 55.7114C22.6403 55.7999 23.1652 55.7075 23.6144 55.4502C24.0636 55.1929 24.4089 54.7869 24.5907 54.3022C24.7725 53.8175 24.7793 53.2845 24.61 52.7953C24.4408 52.3061 24.106 51.8913 23.6635 51.6225L13.4635 47.8448L10.1969 46.667L7.73021 60.7781C7.64469 61.342 7.77925 61.9172 8.10604 62.3846C8.43282 62.852 8.92681 63.1759 9.48576 63.2892H9.86354C10.3866 63.2987 10.8962 63.1234 11.3027 62.7942C11.7092 62.4649 11.9865 62.0028 12.0858 61.4892L13.0191 56.1559C15.5325 60.5587 19.0628 64.2963 23.3153 67.0565C27.5677 69.8168 32.4188 71.5195 37.4635 72.0225C41.6812 72.4405 45.9398 71.9867 49.9747 70.6892C50.4884 70.4765 50.9037 70.0789 51.1386 69.5749C51.3736 69.0709 51.411 68.4971 51.2435 67.9669C51.0761 67.4366 50.716 66.9884 50.2342 66.7108C49.7524 66.4331 49.1841 66.3461 48.6413 66.467L48.6191 66.5114Z" fill="black"/>
                                <path d="M49.2187 28.8887H31.441C30.8516 28.8887 30.2864 29.1228 29.8696 29.5395C29.4529 29.9563 29.2188 30.5215 29.2188 31.1109V48.8887C29.2188 49.478 29.4529 50.0433 29.8696 50.46C30.2864 50.8768 30.8516 51.1109 31.441 51.1109H49.2187C49.8081 51.1109 50.3734 50.8768 50.7901 50.46C51.2068 50.0433 51.441 49.478 51.441 48.8887V31.1109C51.441 30.5215 51.2068 29.9563 50.7901 29.5395C50.3734 29.1228 49.8081 28.8887 49.2187 28.8887ZM46.9965 46.6664H33.6632V33.3331H46.9965V46.6664Z" fill="black"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_47_5287">
                                <rect width="80" height="80" fill="white" transform="translate(0.330078)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span className="block">Choose a plan and get unlimited design requests.</span>
                    </div>
                </div>
                <div className="">
                    <div className="relative text-center bg-colPinkA md:mx-20 py-24 md:py-16 overflow-hidden">
                        <img data-aos="fade-left" data-aos-duration="1500" className="absolute right-0 top-[-37px] w-[120px] h-[120px] m-auto object-cover" src="/images/sketches/sketch_003.png" width={0} height={0} sizes="100vw" alt=""/>
                        <img data-aos="fade-right" data-aos-duration="1500" className="absolute left-0 bottom-[5px] w-[108px] h-[90px] m-auto object-cover" src="/images/sketches/sketch_004.png" width={0} height={0} sizes="100vw" alt=""/>
                        <div data-aos="fade-up" data-aos-duration="1500">
                            <img className="w-[35px] h-[30px] m-auto object-cover" src="/images/others/other_002.png" width={0} height={0} sizes="100vw" alt=""/>
                            <div className="my-6 m-auto px-4 md:px-0 md:max-w-[45%] font-familyA font-semibold leading-tight tracking-wide text-3xl">
                                <span>Design adds value faster than it adds costs.</span>
                            </div>
                            <div className="flex items-center justify-center font-medium">
                                <span>Joel Spolsky,</span>
                                <img className="mx-[6px] w-[16px] h-[16px] object-cover" src="/images/others/other_003.png" width={0} height={0} sizes="100vw" alt="hyper"/>
                                <span className="font-bold">Trello</span>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperA>
        </div>
    );
}

export default Home003;