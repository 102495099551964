
import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import WrapperA from "../widgets/wrapper/WrapperA";

const Home007 = ({id = '', name = '', className = '', onView = () => {}}: typeHomeA) => {

    const localRef = useRef(null);
    const isInView = useInView(localRef);

    useEffect(() => {
        if(isInView){
            onView({
                name: name
            })
        }
    }, [isInView])

    const things = [
        'Websites', 'Slide decks', 'Trade show banners', 'Mobile apps', 'Stage Backdrops', 'Email graphics',
        'Logos & branding', 'Infographics', 'Design Systems', 'Video Editing', 'Brochures', 'Business cards',
        'SAAS', 'Signage', 'Packaging', 'Social media graphics', 'Brand guides', 'Blog graphics',
        'Publications', 'Event graphics', 'Digital ads', 'Pitch decks', 'Billboards', 'Brochures'
    ];
  
    return (
        <div ref={localRef} id={id} className={`${className} relative`}>
            <div className="absolute left-0 top-0 w-[44%] h-[480px] set_bg" style={{backgroundImage: 'url(/images/noises/noise_006.png)'}}></div>
            <WrapperA className="relative px-4 md:px-20 pt-24 pb-24 md:pt-28 md:pb-36">
                <img data-aos="fade-down" data-aos-duration="1500" className="absolute left-[50%] top-[-50px] w-[6px] h-[100px] m-auto object-fill translate-x-[-50%]" src="/images/sketches/sketch_009.png" width={0} height={0} sizes="100vw" alt=""/>
                <div data-aos="fade-up" data-aos-duration="1500">
                    <div className="mb-24 md:mb-32 text-center font-familyA font-semibold leading-tight tracking-wide text-3xl md:text-5xl">
                        <span>Apps, websites, logos & more.</span>
                    </div>
                    <div className="relative md:flex flex-wrap justify-between p-8 md:p-12 pt-12 border-[6px] border-colPinkB font-bold text-md">
                        <img data-aos="fade-right" data-aos-duration="1500" className="absolute left-[-50px] top-[-45px] w-[100px] h-[90px] object-fill" src="/images/sketches/sketch_012.png" width={0} height={0} sizes="100vw" alt=""/>
                        {
                            things.map((thing: any, a: number) => {
                                return (
                                    <div key={a} className="md:w-[calc(33%-30px)] pb-4 mb-6 border-b-[6px] border-colPinkB">{thing}</div>
                                )
                            })
                        }
                    </div>
                </div>
            </WrapperA>
        </div>
    );
}

export default Home007;