import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import WrapperA from "../widgets/wrapper/WrapperA";
import Home009X01 from "./Home009X01";

const Home009 = ({id = '', name = '', className = '', onView = () => {}}: typeHomeA) => {

    const [states, setStates] = useState<any>({
        things: {
            currentIndex: -1,
            data: []
        }
    });

    const localRef = useRef(null);
    const isInView = useInView(localRef);

    useEffect(() => {
        if(isInView){
            onView({
                name: name
            })
        }
    }, [isInView])

    useEffect(() => {
        const data: any = [
            {
                text_a: `Why wouldn't I just hire a full-time designer?`,
                text_b: <>
                            Hiring a senior-level designer can be very expensive, and finding one who is available and a good fit for your company can be super challenging. Additionally, you may not always have enough work to keep them busy at all times, so {`you're`} stuck paying highly for time you {`aren't`} able to utilize.
                            <br/><br/>
                            With Hyper Pels, you can get high-quality and professional design work for an affordable flat monthly fee without the hassle of hiring a full-time designer. On the Pro Plan, you can sit back, relax, and have us come up with design ideas to help you better position your brand and keep your design pipeline running.
                        </>
            },
            {
                text_a: `Is there a limit to how many requests I can have?`,
                text_b: `There is no limit to the number of design requests you can submit. You can submit as many design requests as you want, and we will work on them one at a time. You will receive your first draft of active design requests (2 at a time for Pro Users) within 1-2 business days or less! You can request unlimited revisions until you're 100% satisfied.`
            },
            {
                text_a: `How fast will I receive my designs?`,
                text_b: `You will receive your first draft of active design requests on average in 1-2 business days or much less, all depending on scope. If you have a Pro subscription, you can have up to two active design requests at a time. You can request unlimited revisions until you're 100% satisfied. We will work hard to ensure that you receive high-quality and professional design work as quickly as possible`
            },
            {
                text_a: `Are there other payment methods?`,
                text_b: `If you are unable to subscribe to a plan via the available options, reach out to us right away so we can walk through the best alternatives together.`
            },
            {
                text_a: `How does the Content Ideas feature work?`,
                text_b: `Hyper Pels' Content Ideas feature is designed to help you come up with design content that will better position your brand. We start by getting to know your brand and your target audience, then conduct research to identify the best content ideas for your brand. We create a content strategy that aligns with your brand's goals and values and work with you to create high-quality and professional design work that brings your brand to life.`
            },
            {
                text_a: `What programs do you design in?`,
                text_b: `Hyper Pels' uses a variety of design software to create high-quality and professional design work. Some of the programmes we use include Figma, Adobe Photoshop, and Adobe Illustrator.`
            },
            {
                text_a: `How do I request designs?`,
                text_b: `Hyper Pels offers a lot of flexibility in how you request designs using our chosen platform, Coda. You can submit as many design requests as you want through your Coda board, attaching as many links or files as required. You can also communicate directly via your dedicated Slack channel or through secondary means such as Vidyard or Loom.`
            },
            {
                text_a: `What if I don't like the design?`,
                text_b: `No worries! We'll continue to revise the design until you're 100% satisfied.`
            },
            {
                text_a: `Is there any design work you don't cover?`,
                text_b: `Hyper Pels can create a wide range of design work. However, there are some design requests that we don't cover, such as illustrations, 3D modelling and animated graphics (GIFS, etc.).`
            },
            {
                text_a: `How does the custom development feature work?`,
                text_b: `By subscribing to the custom development plan as an add-on to your design subscription service, we can create high-end websites, web applications and mobile applications (Android and iOS) for you. You get a separate board where you can manage your development project(s) and receive timely feedback and results. Our team and structures are well positioned to make the process of creating websites and applications that are visually appealing, user-friendly, and optimized for search engines a breeze.`
            },
            {
                text_a: `Are there any refunds if I don't like the service?`,
                text_b: `Chances that you wouldn't are almost nonexistent! Anyway, as a new client of Hyper Pels, you get to enjoy our 14-day refund policy. If you're not happy with our service, which most likely would not be the case, you can get 50% of your payment back and still request design work for the first 2 weeks. That's how confident we are in our service. We want to ensure that you're completely satisfied with our service and the designs we create for you.`
            }
        ];
        setStates({
            ...states,
            things: {
                ...states.things,
                data
            }
        });
    }, [])

    const onThingClick = (obj: {index: number}) => {
        let currentIndex = -1;
        if(obj.index !== states.things.currentIndex){
            currentIndex = obj.index;
        }
        setStates({
            ...states,
            things: {
                ...states.things,
                currentIndex
            }
        });
    }
  
    return (
        <div ref={localRef} id={id} className={`${className} relative`}>
            <div className="absolute left-0 top-[-60px] w-[44%] h-[480px] set_bg" style={{backgroundImage: 'url(/images/noises/noise_008.png)'}}></div>
            <div className="relative px-4 md:px-20 pt-28 pb-20">
                <img data-aos="fade-right" data-aos-duration="1500" className="absolute left-0 top-[-50px] md:w-[190px] md:h-[157px] m-auto object-fill" src="/images/sketches/sketch_020.png" width={0} height={0} sizes="100vw" alt=""/>
                <img data-aos="fade-down" data-aos-duration="1500" className="absolute left-[37.5%] md:left-[50%] top-[-53px] w-[102px] h-[106px] m-auto object-fill md:translate-x-[-50%]" src="/images/sketches/sketch_014.png" width={0} height={0} sizes="100vw" alt=""/>
                <img data-aos="fade-left" data-aos-duration="1500" className="absolute right-0 top-[-60px] md:w-[140px] md:h-[219px] m-auto object-fill" src="/images/sketches/sketch_021.png" width={0} height={0} sizes="100vw" alt=""/>
                <div data-aos="fade-up" data-aos-duration="1500">
                    <WrapperA>
                        <div className="mb-14 m-auto md:w-[65%]">
                            {
                                states.things.data.map((thing: any, a: number) => {
                                    return (
                                        <Home009X01 key={a} index={a} textA={thing.text_a} textB={thing.text_b} show={a === states.things.currentIndex} onClick={onThingClick}/>
                                    )
                                })
                            }
                        </div>
                        <span className="block w-[fit-content] m-auto font-familyA text-3xl md:text-5xl font-bold tracking-wide">FAQs</span>
                    </WrapperA>
                </div>
            </div>
        </div>
    );
}

export default Home009;