
export const defUrls = {
    app: {
        api: {
            // base: 'http://localhost',
            base: 'https://hyperpels.com',
            // broadcast: `http://localhost:4000/v1/broadcast/`,
            broadcast: `https://hyper-broadcast-api.vercel.app/v1/broadcast/`,
        }
    }
};