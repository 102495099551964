
export const receipient_details: any = {
    USD: {
        logo_name: 'flag_001.png',
        name: 'US Dollar',
        symbol: '$',
        holder: {
            'Account Holder': 'Tejumade Olomola',
            'Bank Name': 'WELLS FARGO BANK. N.A.',
            'Account Number': '40630185956121287',
            'Routing Number': '121000248',
            'Account Type': 'Checking',
            'Address': '651 North Broad Street, Suite 206, Middletown , 19709 Delaware, USA'
        }
    },
    GBP: {
        logo_name: 'flag_002.png',
        name: 'British Pound',
        symbol: '£',
        holder: {
            'Account Holder': 'Tejumade Olomola',
            'Bank Name': 'Clear Junction Limited',
            'Account Number': '39988158',
            'Sort Code': '041307',
            'IBAN': 'GB43CLJU04130739988158',
            'Address': '16 Mortimer Street ,London , W1T 3JL United Kingdom'
        }
    },
    EUR: {
        logo_name: 'flag_003.png',
        name: 'Euro',
        symbol: '€',
        holder: {
            'Account Holder': 'Tejumade Olomola',
            'Bank Name': 'Clear Junction Limited',
            'IBAN': 'GB43CLJU04130739988158 ',
            'Bic Code': 'GB43CLJU',
            'Swift Code': 'CLJUGB2AXXX',
            'Address': '16 Mortimer Street ,London , W1T 3JL United Kingdom'
        }
    },
    NGN: {
        logo_name: 'flag_004.png',
        name: 'Naira',
        symbol: '₦',
        holder: {
            'Account Holder': 'Tejumade Olomola',
            'Bank Name': 'Guarantee Trust Bank',
            'Account Number': '0266461741',
            'Account Type': 'Savings',
        }
    }
}