import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { onAnchorGoTo } from "../../defs/funcs";
import { defNavs } from "../../defs/navs";
import { useSearchParams } from "react-router-dom";

interface typeLocal {
    className?: string;
    objInView?: {name: string};
    toggleNav?: boolean;
}
const Home000 = ({className = '', objInView = {name: ''}}: typeLocal) => {
    const [searchParams] = useSearchParams();
    const anchor = searchParams.get('ref');

    const [states, setStates] = useState<any>({
        navs: [],
        variants: {
            hidden: {opacity: 0, bottom: '-100px'},
            visible: {
                opacity: 1,
                bottom: '30px',
                transition: {
                    delay: 1,
                    type: 'spring',
                    bounce: 0.35
                }
            }
        }
    });

    useEffect(() => {
        if(anchor === 'faq'){
            onAnchorGoTo('_010');
        }
        setStates({
            ...states,
            navs: defNavs
        });
    }, []);
  
    return (
        <>
            <div className={`${className}`}>
                <motion.div
                    className="hidden fixed z-[1] left-[50%] bottom-[30px] translate-x-[-50%] border-[1px] border-white w-[fit-content] m-auto p-[4px] rounded-[100vw] bg-black md:flex items-center text-white fill-white"
                    initial="hidden"
                    animate="visible"
                    variants={states.variants}
                >
                    {
                        states.navs.map((nav: any, a: number) => 
                            <span
                                key={a}
                                className={`
                                    block font-familyA tracking-wide text-sm rounded-[40px] px-5 py-4 transitionA cursor-pointer whitespace-nowrap inline-block
                                    ${nav.text === 'Login' ?
                                        'bg-white/[.16] hover:bg-white/[.26]' :
                                        nav.text === objInView.name ?
                                        'bg-white text-black' :
                                        'hover:bg-white hover:text-black'
                                    }
                                `}
                                // href={`#${nav.link}`}
                                onClick={() => onAnchorGoTo(nav.id)}
                            >
                                {nav.text}
                            </span>
                        )
                    }
                    <span
                        // href="#001"
                        className="shrink-0 flex rounded-[40px] px-7 py-3 transitionA cursor-pointer bg-white/[.16] hover:bg-white/[.26]"
                        onClick={() => onAnchorGoTo('_001')}
                    >
                        <img className="w-[12px] h-auto max-w-[initial]" src="/images/vectors/vector_008.png" width={0} height={0} sizes="100vw" alt=""/>
                    </span>
                </motion.div>
            </div>
        </>
    );
}

export default Home000;