
interface typeLocal {
    textA: string;
    textB: string;
    index: number;
    show?: boolean;
    onClick?: any;
}

const Home009X01 = ({textA, textB, index, show = false, onClick = () => {}}: typeLocal) => {

    // const [states, setStates] = useState({
    //     show: false
    // });
  
    return (
        <div className="border-b-[1.5px] border-black pb-8 mb-8 cursor-pointer" onClick={() => onClick({index})}>
            <div className="flex items-center justify-between">
                <span className="font-bold text-lg">{textA}</span>
                <img className={`${show ? 'rotate-[180deg]' : ''} shrink-0 ml-4 w-[16px] h-[16px] object-fill transitionA`} src="/images/vectors/vector_007.png" width={0} height={0} sizes="100vw" alt=""/>
            </div>
            {
                show &&
                <span className="block mt-4">{textB}</span>
            }
        </div>
    );
}

export default Home009X01;